import React from "react";
import "./EmployeRow.css";

const EmployeRow = ({ name, planned, working, paused, finished, cancled, apps, total }) => {

    const isZeroPlanned = planned?.red || planned?.orange || planned?.normal
    const isNotZeroWorking = working.count > 0
    const isNotZeroPaused = paused.count > 0
    const isNotZeroFinished = finished.count > 0

    const classColorWorking = working.top === 1 && isNotZeroWorking ? "top_color_red" : working.top === 2 && isNotZeroWorking ? "top_color_orange" : "default_color"
    const classColorPaused = paused.top === 1 && isNotZeroPaused ? "top_color_red" : paused.top === 2 && isNotZeroPaused ? "top_color_orange" : "default_color"
    const classColorFinished = finished.top > 0 && isNotZeroFinished ? "top_finished" : "default_color"

    const titleWorking = working.numbersList?.reduce((acc, numberObj) => acc + numberObj.number + '| ' + numberObj?.department_name || '' + '\n', '')
    const titlePlanned = planned.numbersList?.reduce((acc, numberObj, i) => acc + numberObj.number + '| ' + numberObj?.department_name || '' + ' | ' + planned.created_atList[i].created_at + '\n', '')
    const titlePaused = paused.numbersList?.reduce((acc, numberObj) => acc + numberObj.number + '| ' + numberObj?.department_name || '' + '\n', '')
    const titleFinished = finished.numbersList?.reduce((acc, numberObj) => acc + numberObj.number + '| ' + numberObj?.department_name || '' + '\n', '')
    const titleCancled = cancled.numbersList?.reduce((acc, numberObj) => acc + numberObj.number + '| ' + numberObj?.department_name || '' + '\n', '')

    const queue = (planned.red || 0) + (planned.orange || 0) + (planned.normal || 0) + working.count + paused.count


    return (
        <div className="table_applications">
            <div className="table_applications_implementers">
                <p className="employee_name" title={`Тотал։ ${total}`}>{name}</p>
                <div className="table_applications_counts">
                    <p className="department_employe_count default_color">{queue}</p>
                    <div title={titlePlanned} className="department_employe_count department_employe_count_list">
                        {planned?.red ? <p className="department_employe_counts bgRed">{planned.red}</p> : null}
                        {planned?.orange ? <p className={"department_employe_counts bgOrange"}>{planned.orange}</p> : null}
                        {planned?.normal ? <p className={"default_color"}>{planned.normal}</p> : null}
                        {!isZeroPlanned ? <p className={"default_color"}>{0}</p> : null}
                    </div>
                    <p title={titleWorking} className={`department_employe_count ${classColorWorking}`}>{working.count}</p>
                    <p title={titlePaused} className={`department_employe_count ${classColorPaused}`}>{paused.count}</p>
                    <p title={titleFinished} className={`department_employe_count ${classColorFinished}`}>{finished.count}</p>
                    <p title={titleCancled} className="department_employe_count default_color">{cancled.count}</p>
                </div>
            </div>
        </div>
    );
};

export default EmployeRow;
